<template>
  <card v-if="$route.params.ContractId">
    <el-tabs :tab-position="tabPosition" v-model="nowTab" @before-leave="beforeLeave" @tab-click="beforeLeave">
      <el-tab-pane :label="$t('Корпоративная политика')" name="policy" v-if="showMenu(nameObject.corpPolity)">
        <CorpPolityContractPage v-if="nowTab == 'policy'" :hasReadOnlyInTable="loadData?!loadData.readOnly:true" />
      </el-tab-pane>
      <el-tab-pane :label="$t('Коммерческие условия')" name="terms" v-if="showMenu(nameObject.commercialTerms)">
        <ContractCommercialTermsPage v-if="nowTab == 'terms'" :hasReadOnlyInTable="loadData?!loadData.readOnly:true"/>
      </el-tab-pane>
      <el-tab-pane :label="$t('Атрибуты')" name="attrVal" v-if="showMenu(nameObject.attrEntity)">
        <AttrValueTable
          v-if="nowTab == 'attrVal'"
          routeField="ContractId"
          :prefixEditIn="`/contract/${$route.params.ContractId}`"
          :hasReadOnlyInTable="loadData?!loadData.readOnly:true"
        />
      </el-tab-pane>

      <template >
        <el-tab-pane :label="$t('Коды структурные')" name="code_pers" v-if="showMenu(nameObject.codesAgent)">
          <table-page-stuctor-code-contract
            v-if="nowTab == 'code_pers'"
            :linkCreate="`/contract/${$route.params.ContractId}/ContractCodeStructor/create`"
            :customDelete="true"
            :apiMethodDeleteIn="apiMethodDeleteInStructurCode"
            :deleteParamsIn="deleteParamsInStuctureCode"
            :hasReadOnlyInTable="loadData?!loadData.readOnly:true" />

        </el-tab-pane>
        <el-tab-pane :label="$t('Коды бюджетные')" name="code_budg" v-if="showMenu(nameObject.codesAgent)">
          <TablePageBudgetСodeContract
             v-if="nowTab == 'code_budg'"
            :linkCreate="`/contract/${$route.params.ContractId}/ContractCodeBudget/create`"
            :customDelete="true"
            :apiMethodDeleteIn="apiMethodDeleteInStructurCode"
            :deleteParamsIn="deleteParamsInStuctureCode"
            :hasReadOnlyInTable="loadData?!loadData.readOnly:true"
           />
        </el-tab-pane>
        <el-tab-pane :label="$t('Персоны')" name="person" v-if="showMenu(nameObject.travelers)">
          <TablePagePerson v-if="nowTab == 'person'" />
        </el-tab-pane>
        <el-tab-pane :label="$t('Роли')" name="roleBlock">
          <TableRoleContract v-if="nowTab == 'roleBlock'" />
        </el-tab-pane>

      </template>
      <el-tab-pane
        :label="$t('Ремарки Sabre')"
        name="sabreRemark"
        v-if="hasSabreTab"
      >
        <SabreRemarkTable v-if="nowTab == 'sabreRemark'" />
      </el-tab-pane>
    </el-tabs>
  </card>
</template>
<script>
import { Tabs, TabPane } from "element-ui";
const DELETE_CODE_CONTRACT = "DELETE_CODE_CONTRACT";
const SET_TAB_CONTRACT = "SET_TAB_CONTRACT";
import CorpPolityContractPage from "../../../modules/corpPolicy/CorpPolityContractPage";
import ContractCommercialTermsPage from "../../../modules/commercialTerms/ContractCommercialTermsPage";

import AttrValueTable from "../attrValue/AttrValueTable";

import SabreRemarkTable from "../../../modules/sabreRemark/SabreRemarkTable";
import clearReactive from "../../../../helpers/clearReactive"
import { NAME_OBJECT, NAME_ACTION } from "../../../../RoleConstanans";


import TablePageBudget from "../../../modules/code/TablePageBudget.vue";
import TablePageStuctorCodeContract from '../code/TablePageStuctorCodeContract.vue';
import TablePageBudgetСodeContract from '../code/TablePageBudgetСodeContract.vue';
import TablePagePerson from "../person/TablePagePerson.vue"
import { API_METHODS } from '../../../../constant';
import TableRoleContract from "@/components/v2/module/contract/TableRoleContract.vue";
export default {
  name: "ContractData",
  components: {
    TableRoleContract,
    ElTabs: Tabs,
    ElTabPane: TabPane,
    CorpPolityContractPage,
    ContractCommercialTermsPage,
    AttrValueTable,
    TablePageBudget,
    SabreRemarkTable,
    TablePageStuctorCodeContract,
    TablePageBudgetСodeContract,
    TablePagePerson

  },
  props: {
    loadDataIn: { type: Object },
    activeShowMenu: { type: Boolean, default: false }
  },
  created(){
    this.loadData = clearReactive(this.loadDataIn)
  },
  data() {
    return {
      tabPosition: "top",
      loadData: null,
      nameObject: NAME_OBJECT,
      apiMethodDeleteInStructurCode:  API_METHODS.DELETE_CODE_CONTRACT
    };
  },
  computed: {
    hasSabreTab() {
      return this.loadData.contractTypeCode === "agency_to_client";
    },
    isAgencyToClient() {
      if (this.loadData && this.loadData.contractTypeCode) {
        return this.loadData.contractTypeCode === "agency_to_client";
      }
      return false;
    },
    nowTab: {
      set(tab) {
        this.$store.commit(SET_TAB_CONTRACT, tab);
      },
      get() {
        return this.$store.state.contract.tabnow;
      },
    },
  },
  methods: {
    beforeLeave(){
      this.$store.commit("CLEAR_TABLE_PAGINATOR", { delUrl: this.$route.path });
    },
    async deleteElCustom(codeId) {
      const paramsIn = {
        self: this,
        CodeId: codeId,
      };
      try {
        let res = await this.$store.dispatch(DELETE_CODE_CONTRACT, paramsIn);
        return res;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    showMenu(name) {
      if (this.activeShowMenu) {
        let isActionAccess = this.$store.getters.isActionAccess;
        return isActionAccess(name, NAME_ACTION.isMenu, name, "showMenu");
      }
      return true;
    },
    deleteParamsInStuctureCode(codeId){
      return { CodeId: codeId }
    },
  },
};
</script>
