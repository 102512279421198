<template>
  <common-page-table
    v-if="showTable"
    :titleTable="$t('TableRoleContract')"
    :hasCustomEdit="true"
    :customEdit="customEdit"
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :prefixEdit="`/contract/${$route.params.ContractId}/person`"
    :prefixShow="`/contract/${$route.params.ContractId}/person`"
    :link-create="`/contract/${$route.params.ContractId}/person/create`"
    :keyTable="'personRoleContractId'"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :force-is-create="false"
  >

    <div  class="row blockAddRole">
      <BaseInputNumber
        v-if="configRoleId"
        :config="configRoleId"
        :form="loadData"
        :readonly="false"
      />
      <BaseInput
        v-if="configRoleName"
        :config="configRoleName"
        :form="loadData"
        :readonly="false"
      />
      <BaseCheckbox  v-if="configIsDefault"
                     :config="configIsDefault"
                     :form="loadData"
                     :readonly="false" />

      <div class="col-2" v-if="!isEditMode">
        <label>&nbsp;</label>
        <l-button
          type="success"
          wide
          block
          @click="addRole"
          :disabled="!hasSave"
        >{{ $t("Добавить") }}
        </l-button>
      </div>

      <div class="col-2" v-if="isEditMode">
        <label>&nbsp;</label>
        <l-button
          type="success"
          wide
          block
          @click="saveEdit"
          :disabled="!hasSave"
        >{{ $t("Изменить") }}
        </l-button>
      </div>
      <div class="col-2"  v-if="isEditMode">
        <label>&nbsp;</label>
        <l-button
          type="warning"
          wide
          block
          @click="cancelEdit"
        >{{ $t("Отмена") }}
        </l-button>
      </div>
    </div>


  </common-page-table>
</template>
<script>
import { NAME_OBJECT, LIST_EQUIVALENT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import {API_METHODS, FIELD_LANG_PERSON, TYPE_ERRORS} from "../../../../constant";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
import formatData from "../../../../helpers/converters/formatData";
import BaseInput from "@/components/modules/generator/BaseComponets/BaseInput.vue";
import BaseInputNumber from "@/components/modules/generator/BaseComponets/BaseInputNumber.vue";
import BaseCheckbox from "@/components/modules/generator/BaseComponets/BaseCheckbox.vue";
import {ApiService} from "@/util/ApiService";
import notify from "@/helpers/notify/notify";
export default {
  name: "TableRoleContract",
  components: {BaseCheckbox, BaseInputNumber, BaseInput, CommonPageTable },
  data() {
    return {
      isEditMode: false,
      rowEdit: null,
      showTable: true,
      loadData: {
        corteosRoleId: 0,
        corteosRoleName: "",
        isDefault: false
      },
      configRoleId: null,
      configRoleName: null,
      configIsDefault: null,
      fieldLang: "FIELD_LANG_PERSON",
      apiName: API_METHODS.GET_CONTRACT_LIST_ROLE,
      apiDeleteName: API_METHODS.DELETE_CORTES_CONTRACT_ROLE
    };
  },
  computed: {
    hasSave() {
      return !!(this.loadData.corteosRoleId && this.loadData.corteosRoleName);
    },
    paramsGetData() {
      return { ContractId: this.$route.params.ContractId };
    },
    nameRoleEntity() {
      return NAME_OBJECT.clientPerson;
    }
  },
  created() {
    this.configRoleId =
      this.getCustomConfigForInput("INPUT_NUMBER", 'corteosRoleId', 'corteosRoleId', 'ID роли в  Кортеос')
    this.configRoleName =
      this.getCustomConfigForInput("INPUT", 'corteosRoleName', 'corteosRoleName', 'Название роли в  Кортеос')
    this.configIsDefault =
      this.getCustomConfigForInput("INPUT", 'isDefault', 'isDefault', 'Роль по умолчанию')
  },
  methods: {
    cancelEdit() {
      this.loadData.corteosRoleId  = 0;
      this.loadData.corteosRoleName  = "";
      this.loadData.isDefault  = false;
      this.isEditMode = false
    },
    customEdit(row){
      this.isEditMode = true
      this.rowEdit = row;
      this.loadData.corteosRoleId  = row.corteosRoleId;
      this.loadData.corteosRoleName  = row.corteosRoleName;
      this.loadData.isDefault  = row.isDefault;


    },
    getCustomConfigForInput(type = "INPUT_NUMBER", name = '', fieldName = '', label = '', col = 2) {
      return {
        type: type,  ////src/helpers/generatorForm/constants.js
        description: "Текстовое поле",
        main: false,
        default: null,
        colsXS: col,
        colsSM: col,
        colsMD: col,
        colsLG: col,
        OffsetXS: 0,
        OffsetSM: 0,
        OffsetMD: 0,
        OffsetLG: 0,
        addClass: "",
        name: name,
        fieldName: fieldName,
        label: label,
        placeholder: null,
        validationRule: null,
        errName: null,
        cyData: null,
        enable: true,
        readonly: false,
        showTable: true,
        disableInCreate: false,
        relative: null,
        SearchType: "String",
        subListId: 0,
        isReadOnlyAdd: false,
        isReadOnlyEdit: false,
        watchAttr: null,
      };
    },

   async  addRole(){
      const {
        corteosRoleId,
        corteosRoleName,
        isDefault
      } = this.loadData;
      try {
        const params = {
          self: this,
          saveData: {
            subjectContractId: Number(this.$route.params.ContractId),
            corteosRoleId: Number(corteosRoleId),
            corteosRoleName: String(corteosRoleName),
            isDefault: Boolean(isDefault)
          },
          query: {
            lang: this.$store.state.common.lang
          }
        };
        this.load = true;
        const res = await ApiService(API_METHODS.CREATE_CORTES_CONTRACT_ROLE, params);
        this.showNotification(res)
        this.reinitData();
        this.load = false;
      } catch (err) {
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
        this.load = false;
      }
    },





    async  saveEdit(){
      const {
        corteosRoleId,
        corteosRoleName,
        isDefault
      } = this.loadData;
      try {
        const params = {
          self: this,
          saveData: {
            ...this.rowEdit,
            subjectContractId: Number(this.$route.params.ContractId),
            corteosRoleId: Number(corteosRoleId),
            corteosRoleName: String(corteosRoleName),
            isDefault: Boolean(isDefault)
          },
          query: {
            lang: this.$store.state.common.lang
          }
        };
        this.load = true;
        const res = await ApiService(API_METHODS.UPDATE_CORTES_CONTRACT_ROLE, params);
        this.showNotification(res)
        this.reinitData();
        this.load = false;
        this.cancelEdit();
      } catch (err) {
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
        this.load = false;
      }
    },

    reinitData() {
      this.showTable = false;
      this.$nextTick(() => {
        this.showTable = true;
      })
    },

    showNotification(res) {
      if (res.isValid)
        notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
      else
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
    },

    // convertData(dataNow, langNow) {
    //   let res = convertLangDataToTable(dataNow, langNow, FIELD_LANG_PERSON);
    //
    //   res = res.map(person => {
    //     return { ...person, dateBirth: formatData(person.dateBirth) };
    //   });
    //   return res;
    // },
    deleteParams(id) {
      return {
        personRoleContractId: id
      };
    }
  }
};
</script>

<style >
.blockAddRole{
  margin-left: 0;
  margin-right: 0;
}
</style>
